<template>
	<div class="amap">
		<el-amap :zoom="zoom" :center="center">
			<!-- <el-amap-marker :position="[117.000923, 36.675807]" :events="events" :clickable="true"  :animation="animation" :title="title" :icon="icon" /> -->
			<el-amap-marker
				v-for="marker in markers"
				:position="marker.position"
				:key="marker.id"
				:events="marker.events"
				:icon="marker.icon"
				:title="marker.title"
			></el-amap-marker>
			<el-amap-info-window
				v-if="window"
				:position="window.position"
				:visible="window.visible"
				:content="window.content"
				:offset="window.offset"
			></el-amap-info-window>
		</el-amap>
	</div>
</template>

<script>
export default {
	props: {
		searchList: {
			type: Object,
			default(){
				return {};
			}
		},
		mapType: {
			type: Number,
			default:1,
		},
		currentMap:{
			type: Object,
			default(){
				return {};
			}
		},
		zoomInAndOut:{
			type: Boolean,
			default: false,
		}
	},
	components: {},
	data() {
		return {
			center: [116.4239393, 39.93606967],
			markers: [],
			windows: [],
			window: '',
			zoom: 11,
		}
	},
	watch: {
		searchList: {
			handler() {
				this.point()
			},
			deep: true,
		},
		currentMap:{
			handler(val) {
				let initCenter = [];
				initCenter.push(val.baiduLon,val.baiduLat)
				this.center = initCenter;
			},
			deep: true,
		},
		zoomInAndOut:{
			handler(bool) {
				this.zoom = bool?13:11;
			},
			deep: true,
		}
	},
	
	mounted() {
		window.clickFun = this.clickFun
		this.point()
	},
	
	methods: {
		clickFun(item) {
			this.$emit('windowsClick', item)
		},

		point() {
			
			let markers = [];
			let windows = [];
			let that = this;
			
			if (JSON.stringify(this.searchList) != '{}' && this.mapType == 1 && this.searchList.list && this.searchList.list.length>0) {
              
				let initCenter = []
				if(this.searchList.list.length>0){
					this.searchList.list.map((item,index)=>{
						if(index==0){
							if(!item.baiduLon){
								item.baiduLon = 1;
							}
							if(!item.baiduLat){
								item.baiduLat = 1;
							}
						}
						return item
					})
					initCenter.push(this.searchList.list[0].baiduLon,this.searchList.list[0].baiduLat)
				}
				
				that.center = initCenter
				this.searchList.list.forEach((item, index) => {
					markers.push({
						position: [item.baiduLon, item.baiduLat],
						icon: `http://39.106.172.100:8303/static/images/hotel/poi/poi${index + 1}.png`,
						title: item.address,
						
						events: {
							click() {
								that.windows.forEach((window) => {
									window.visible = false //关闭窗体
								})
								that.window = that.windows[index]
								let itemCenter = []
								itemCenter.push(item.baiduLon, item.baiduLat)
								that.center = itemCenter
								that.$nextTick(() => {
									that.window.visible = true //点击点坐标，出现信息窗体
								})
							},
						},
					})
					windows.push({
						position: [item.baiduLon, item.baiduLat],
						content: `<div class="title">${index + 1}-${item.jdmc}</div>
                                <div class="center">                               
									<img src="${item.img}"/>
									<div class="center-right">
										<p>地址:${item.address}</p>
										<p>电话：010-64279933<p/>
										<p class="center-right-text">￥${item.price}起</p>
										<div class="bottom">
											<span onclick="clickFun(${item.jdid})" class="bottom-span">预订</span>
										</div> 
									</div>   							
                                </div>                      
                        `,
						offset: [20, -15], //窗体偏移
						visible: false, //初始是否显示
					})
				})
			} else if (this.mapType == 2) {
				// console.log('进入this.mapType==2')
				// console.log(this.searchList.list, '我是接受的mapList')
				let initCenter = []
				if (this.searchList.list[0].baiduLon) {
					initCenter.push(
						this.searchList.list[0].baiduLon,
						this.searchList.list[0].baiduLat
					)
				} else {
					initCenter.push(
						this.searchList.list[0].lng,
						this.searchList.list[0].lat
					)
				}
				that.center = initCenter
				if (this.searchList.list[0].baiduLon) {
					this.searchList.list.forEach((item, index) => {
						markers.push({
							position: [item.baiduLon, item.baiduLat],
							icon: `http://39.106.172.100:8303/static/images/hotel/poi/poi${
								index + 1
							}.png`,
							title: item.address,
							events: {
								click() {
									that.windows.forEach((window) => {
										window.visible = false //关闭窗体
									})
									that.window = that.windows[index]
									let itemCenter = []
									itemCenter.push(
										item.baiduLon,
										item.baiduLat
									)
									that.center = itemCenter
								},
							},
						})
					})
				} else {
					this.searchList.list.forEach((item, index) => {
						markers.push({
							position: [item.lng, item.lat],
							// icon:`http://222.128.98.250:8303/static/images/hotel/${index+1}.png` ,
							icon: `http://39.106.172.100:8303/static/images/hotel/poi/poi${
								index + 1
							}.png`,
							title: item.address,
							events: {
								click() {
									that.windows.forEach((window) => {
										window.visible = false //关闭窗体
									})
									that.window = that.windows[index]
									let itemCenter = []
									itemCenter.push(item.lng, item.lat)
									that.center = itemCenter
								},
							},
						})
					})
				}
			} else {
				// console.log('没有数据！')
			}
			//添加点标注
			this.markers = markers
			//生成弹窗
			this.windows = windows
		},
	},
	
}
</script>

<style lang="scss" scoped="scoped">
.amap {
	width: 100%;
	height: 100%;
}

/deep/ .amap-info-sharp {
	display: none;
}
/deep/ .amap-info-content {
	border: solid 1px silver;
	// padding: 0px;
	padding: 0;
	// width: 280px;
	// height: 155px;
	.title {
		color: #333;
		font-size: 14px;
		font-weight: bold;
		padding: 0 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-top: 10px;
		background: none repeat scroll 0 0 #f9f9f9;
		border-bottom: 1px solid #ccc;
		border-radius: 5px 5px 0 0;
	}
	.center {
		box-sizing: border-box;
		padding: 5px;
		display: flex;
		img {
			width: 120px;
			// height: 67px;
		}
		.center-right {
			margin-left: 5px;
			display: flex;
			flex-direction: column;
			.center-right-text {
				font-size: 11px;
				color: #fd712f;
			}
		}
	}
	.bottom {
		width: 100%;
		height: 30px;
		margin-top: 10px;
		.bottom-span {
			float: right;
			width: 60px;
			height: 20px;
			background: #fd712f;
			color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			border-radius: 2px;
			padding: 5px;
		}
	}
}
</style>
