<template>
	<div>
		<el-dialog  title="酒店咨询单" :visible.sync="seePriceShow"  style="text-align:left" class="jdzxd" :before-close="handleClose">
			<div class="bdf0"></div>
			<div class="sp-name">
				<div class="price-header">
					<p class="title">{{seePriceInfo.jdmc}}</p>
					<p class="text"><span class="iconfont yj-icon-dingwei el-icon-location-outline" style="margin-right: 10px;"></span>{{seePriceInfo.phone}}</p>
					<p class="text"><span class="iconfont yj-icon-24gf-telephone2 el-icon-phone-outline"  style="margin-right: 10px;"></span>{{seePriceInfo.address}}</p>
				</div>
			</div>
			<div class="bdf0"></div>
			<div class="sp-con">
				<div class="price-body">
					<p class="c-title">入住需求</p>
					<el-form ref="form" :rules="rules" :label-position="labelPosition" :model="form" class="center">
						<el-row :gutter="24">
							<el-col :span="6">
								<p class="form-t">入住日期</p>
								<el-form-item prop="rzrq">
									<el-date-picker v-model="form.rzrq" value-format="yyyy-MM-dd"
										:picker-options="pickerOptions" @change="changeDate" type="date" placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<p class="form-t">离店日期</p>
								<el-form-item prop="ldrq">
									<el-date-picker v-model="form.ldrq" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										:picker-options="expireTimeOption" type="date" placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<p class="form-t">选择房型</p>
								<el-form-item prop="fxmc">
									<el-select v-model="form.fxid" @input="selectChangeParent" placeholder="房型">
										<el-option v-for="item in roomsOptions" :key="item.fxid" :label="item.fxmc"
											:value="item.fxid">
				
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="3">
								<p style="color:#337ab7;cursor:pointer;margin-top: 28px;line-height: 38px;" @click="roomDialogShow=true"> 查看所选房型</p>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="7">
								<p class="form-t">房间数</p>
								<el-form-item prop="rooms">
									<el-input-number style="" v-model="form.rooms" @change="handleChange" :min="1"
										:max="10"></el-input-number>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="6">
								<p class="form-t">联系人</p>
								<el-form-item prop="linkman">
									<el-input v-model="form.linkman" placeholder="请填写联系人"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<p class="form-t">联系人电话</p>
								<el-form-item  prop="linkMobile">
									<el-input v-model="form.linkMobile" placeholder="请填写联系人电话"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<p class="form-t">联系人邮箱</p>
								<el-form-item prop="linkEmail">
									<el-input v-model="form.linkEmail" placeholder="请填写联系人邮箱"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="18">
								<p  class="form-t">备注</p>
								<el-form-item prop="bzbz">
									<el-input type="textarea" v-model="form.bzbz"></el-input>
								</el-form-item>
								<div class="selectType">
									<span class="setype"
										:class="{setypeActive:chooseSelectIdList.indexOf(item.id)!=-1}"
										v-for="item in setypeList" 
										:key="item.id" 
										@click="getSelectType(item)" >{{item.text}}</span>
								</div>
							</el-col>
						</el-row>
					</el-form>
				</div>	
			</div>
			<div class="bdf0"></div>
			<div class="sp-footer">
				<el-button type="primary" plain @click="handleClose">取消</el-button>
				<el-button type="primary" @click="onSubmit">提交询价</el-button>
			</div>
		</el-dialog>
		<el-dialog title="询价单已提交" :visible.sync="dialogVisible" width="30%" class="dialog" :before-close="closeHander">
			<span>您可在询价单详情中,向后台发送更多需求</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="clodeDialog">确 定</el-button>
				<el-button @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="form.fxmc" :visible.sync="roomDialogShow" class="roomsDialg"
			:before-close="closeRoomDialogShow">
			<div class="center">
				<img :src="selectRooms.imageUrl" v-if="selectRooms.imageUrl">
				<img src="./../../../assets/image/banner/a1.png"  v-else>
				<div class="center-right">
					<p>床型：{{selectRooms.bedType}}</p>
					<p>可住人数：{{selectRooms.floor}}</p>
					<p>上网情况：{{selectRooms.comments}}</p>
					<p>面积：{{selectRooms.area}}</p>
					<p>楼层：{{selectRooms.capacity}}</p>
					<p>描述：{{selectRooms.facilities}}</p>
					<p>备注：{{selectRooms.description}}</p>
				</div>
			</div>
			<div slot="footer">
				<el-button type="primary" @click="roomDialogShow = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
		

</template>

<script>
	export default {
		props: {
			seePriceShow: {
				type: Boolean,
				default: false,
			},
			seePriceInfo: {
				type: Object,
				default(){
					return {}
				},
			},
		},
		watch: {
			seePriceShow() {
				this.$nextTick(() => {
					this.form.rzrq = this.seePriceInfo.checkInDate
					this.form.ldrq = this.seePriceInfo.checkOutDate
					this.getRoom()
					let userInfo = this.$common.sessionget('user_info').empInfo;
					this.form.linkman = userInfo.empName
					this.form.linkMobile = userInfo.phoneNumber
					this.form.linkEmail = userInfo.email
				})
			},
		},
		
		data() {
			return {
				labelPosition: 'right',
				dialogVisible: false,
				roomDialogShow: false,
				selectRooms: {},
				slotType:'123456789',
				form: {
					rooms: 1,
					rzrq: this.seePriceInfo.checkInDate,
					ldrq: this.seePriceInfo.checkOutDate,
					fxmc: '',
					linkEmail: '',
					linkMobile: '',
					linkman: '',
					bzbz: '',

				},
				rules: {
					linkman: [{
						required: true,
						message: '联系人必填',
						trigger: 'blur'
					}],
					linkMobile: [{
						required: true,
						message: '联系电话必填',
						trigger: 'blur'
					} ],
				},
				setypeList:[ //备注信息选项
					{
						text: '含早餐',
						id: 1,
					},
					{
						text: '有窗',
						id: 2,
					},
					{
						text: '有电脑',
						id: 3,
					},
					{
						text: '安静',
						id: 4,
					}
				],
				chooseSelectIdList:[], //选中备注信息
				roomsOptions: [],
				pickerOptions: {
					disabledDate: (time) => {
						return time.getTime() <= this.$moment().subtract(1, 'days')
					},
				},
				expireTimeOption: {
					disabledDate: (time) => { // 离店日期要大于入住日期
						let beginDateVal = this.form.rzrq
						if (beginDateVal) {
							return time.getTime() < new Date(beginDateVal).getTime()
						}
					},
				},
			}
		},
		methods: {
			closeRoomDialogShow() {
				this.roomDialogShow = false
			},
			clodeDialog() {
				// TODO 路由跳转
				this.dialogVisible = false
				this.$emit('update:seePriceShow', false)
			},
			closeHander() {
				this.dialogVisible = false;
			},
			// 获取选中的分类备注
			getSelectType(item){
				if(this.chooseSelectIdList.indexOf(item.id)==-1){
					this.chooseSelectIdList.push(item.id)
					this.form.bzbz = this.form.bzbz+item.text+';';
				}else{
					let index = this.chooseSelectIdList.indexOf(item.id);
					this.chooseSelectIdList.splice(index,1);
				}	
			},
			selectChangeParent(value) {
				let arr = this.roomsOptions.filter(item => item.fxid == value)[0]
				this.form.fxmc = arr.fxmc
				this.selectRooms = arr
				console.log(this.selectRooms, "this.selectRooms")
			},
			getRoom() {
				this.roomsOptions = [];
				this.$conn.getConn('htbook.hotelRoom', {
						jsfile: false
					})({
						findFrom: 1,
						hotelId: this.seePriceInfo.jdid,
						roomId: "",
					})
					.then((res) => {
						this.roomsOptions = res.data

					})
					.catch((err) => {
						this.$message({
							type: 'error',
							message: "未查询到相关的床型！",
						})
					})
			},
			getSaveNeedOrder() {
				let objParams = {
					bzbz: this.form.bzbz,
					fxid: this.form.fxid,
					fxmc: this.form.fxmc || '',
					jdid: this.seePriceInfo.jdid,
					ldrq: this.form.ldrq,
					linkEmail: this.form.linkEmail,
					linkMobile: this.form.linkMobile,
					linkman: this.form.linkman,
					// this.diffDay=this.$dateTime.getDiffDay2(this.value1[0],this.value1[1])
					nights: this.$dateTime.getDiffDay2(this.form.rzrq, this.form.ldrq),
					orderFrom: '10503',
					rooms: this.form.rooms,
					rzrq: this.form.rzrq,
					tripType: "2", // 判断因公因私
				}

				this.$conn
					.getConn('hotelOrder.saveNeedOrder', {
						jsfile: false
					})(objParams)
					.then((res) => {
						if (res.errMsg == "success") {
							this.dialogVisible = true
						}
					})
					.catch((err) => {
						this.$message({
							type: 'error',
							message: "该时间段内，酒店已满房，暂无报价！请重新选择日期，或者选择其他房型！",

						})
					})

			},


			handleClose() {
				this.$emit('update:seePriceShow', false)
				this.resetForm()
			},
			resetForm() {
				this.$refs.form.resetFields();
			},
			changeDate(time) {
				if (time) { // 入住日期发生改变之后，离店日期加一
					this.form.ldrq = this.$moment(this.form.rzrq).add(1, 'days').format('YYYY-MM-DD');
				}
			},
			handleChange() {},
			onSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.chooseSelectIdList = [];
						this.getSaveNeedOrder()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped="scoped">
	.jdzxd{
		font-family: '微软雅黑';
		/deep/ .el-dialog{
			width: 900px; 
			.el-dialog__header{
				width: 900px; 
			}
			 .el-dialog__body{
				width: 900px; 
				padding: 0;
			}
		}
		.bdf0{
			border-bottom: 1px solid #F0F0F0;
		}
		.sp-name{
			padding: 0 30px;
			.price-header {
				margin-top: 20px;
				margin-bottom: 20px;
				text-align: left;
				.title{
					height: 30px;
					font-size: 22px;
					font-weight: 800;
					color: #000000;
					line-height: 30px;
					margin-bottom: 10px;
				}
				.text{
					height: 20px;
					font-size: 14px;
					font-weight: 400;
					color: #424242;
					line-height: 20px;
					margin-bottom: 8px;
				}
			}
		}
		.sp-con{
			padding: 0 30px;
			text-align: left;
			.price-body {
				margin-top: 20px;
				.c-title{
					height: 20px;
					font-size: 15px;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
					margin-bottom: 14px;
				}
				.form-t{
					height: 18px;
					font-size: 12px;
					font-weight: 400;
					color: #808080;
					line-height: 18px;
					margin-bottom: 10px;
				}
				.center {
					width: 100%;
					.selectType{
						margin-bottom: 30px;
						font-size: 12px;
						font-weight: 400;
									
						.setype{
							cursor: pointer;
							display: inline-block;
							*display:inline;
							*zoom:1;
							// vertical-align:middle;
							box-sizing: border-box;
							width: 60px;
							height: 24px;
							line-height: 22px;
							border: 1px solid #CCCCCC;
							margin-right: 20px;
							text-align: center;
						}
						.setypeActive{
							transition: all 0.3s ease;
							border: 1px solid #007AFF;
							color: #007AFF; 
						}
					}
					/deep/ .el-textarea__inner{
						border-radius: 0px;
					}
					.el-input{
						width: 160px;
						
						/deep/ .el-input__inner{
							border-radius: 0px;
							height: 40px;
							line-height: 40px;
						}
					}
					.el-input-number,.el-select{
						/deep/ .el-input__inner{
							border-radius: 0px;
							height: 40px;
							line-height: 40px;
						}
					}
					/deep/ .el-input-number__decrease{
						width: 38px;
						line-height: 38px;
					}
					/deep/ .el-input-number__increase{
						width: 38px;
						line-height: 38px;
					}
				}
			}
		}
		.sp-footer{
			padding: 20px 30px 30px;
			text-align: right;
			.el-button{
				width: 100px;
				height: 40px;
				border-radius: 0px;
				
			}
		}
	}


	.dialog {
		margin-top: 40px;
		text-align: left;
		/deep/ .el-dialog{
			 .el-dialog__body{
				padding: 10px 20px;
			}
		}
	}

	.roomsDialg {
		text-align: left;
		/deep/ .el-dialog{
			width: 600px; 
			.el-dialog__header{
				width: 600px; 
			}
			 .el-dialog__body{
				width: 600px; 
				padding: 0;
			}
		}
		.center {	
			margin-top: 10px;
			padding: 20px;
			img {
				width: 160px;
				height: 142px;
				display: inline-block;
				*display:inline;
				*zoom:1;
				vertical-align:top;
			}

			.center-right {
				width: 390px;
				border: 1px solid #ccc;
				margin-left: 10px;
				display: inline-block;
				*display:inline;
				*zoom:1;
				vertical-align:top;
				box-sizing: border-box;
				padding: 10px;
				text-align: left;
				line-height: normal;
			}
		}
	}
</style>
