//混入,
var mixins = {};

// 
mixins.hotelCxrListCtrl = {

	props: {

	},
	computed: {
	

	},
	methods: {
        // 获取差旅标准信息
		getClbzParamMap(){
            let planSearch = {};
            // 最低出行人职级
            // rooms: state => state.hotel.rooms,
			// cxrList: state => state.hotel.cxrList,
            
			if (this.cxrList && this.cxrList.length> 0) {
				let minEmpRank = 99999;
				let allRank = [];
				let ids = [];
				let empInfo = this.$common.getUserInfo().empInfo;
				let repeat = {};

				let rooms = this.$common.deepCopy(this.rooms || []);
				let cxrList = this.cxrList.map(item=>{
                    return {
                        ...item
                    }
                });

				
				 cxrList = cxrList.map(item => {
					if (item.empRank == void 0) item.empRank = 0;
					if (item.cxrType != 2) {
						ids.push(item.empId);
						allRank.indexOf(item.empRank) == -1 && allRank.push(item.empRank);
						if (item.empRank < minEmpRank) {
							minEmpRank = item.empRank;
							this.minEmpName = item.empName;
						}
					} else {
						// 2023-10-30 bug号：11372 陈伟说 外来人使用预订人差标 传入预订人的id
						ids.push(empInfo.empId)
						repeat[item.empId] = true;
						allRank.indexOf(empInfo.empRank) == -1 && allRank.push(empInfo.empRank);
						if (empInfo.empRank < minEmpRank) {
							minEmpRank = empInfo.empRank;
							this.minEmpName = item.empName;
						}
					}

				});
				let roomEmpMap = {};

				ids.forEach((empId, empIdx) => {
					if (empIdx < 5) {
						roomEmpMap[empIdx + 1] = [empId];
					} else {
						roomEmpMap[empIdx % 5 + 1].push(empId);
					}
				});


				if (minEmpRank != 99999) {
					planSearch.minEmpRank = minEmpRank;
					planSearch.allRank = allRank.join(',');
				}
				planSearch.roomEmpMap = roomEmpMap;
				planSearch.travelerIds = ids;
			}

            return planSearch
        }
	}
}

export default mixins;