import { render, staticRenderFns } from "./HtCnDetails.vue?vue&type=template&id=242b502e&scoped=true&"
import script from "./HtCnDetails.vue?vue&type=script&lang=js&"
export * from "./HtCnDetails.vue?vue&type=script&lang=js&"
import style0 from "./HtCnDetails.vue?vue&type=style&index=0&id=242b502e&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242b502e",
  null
  
)

export default component.exports